import React, { Fragment, useState, useEffect } from 'react';
import emailjs from 'emailjs-com';

import contactKayInLA from '../../assets/images/contactKayInLA.jpg';
import Page from '../../components/Page/Page';
import RowContent from '../../components/Page/RowContent/RowContent';
import Button from '../../components/UI/Button/Button';
import TopButton from '../../components/UI/TopButton/TopButton';
import Modal from '../../components/UI/Modal/Modal';
import Loader from '../../components/UI/Loader/Loader';

function Contact(props) {
  const [contactForm, setContactForm] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState(null);
  const [modalContent, setModalContent] = useState(null);
  const [didLoad, setDidLoad] = useState(false);

  const image = <img style={didLoad ? {} : { visibility: 'hidden' }}
                     src={contactKayInLA}
                     alt="KayInLA standing in front a graffiti wall"
                     onLoad={() => setDidLoad(true)}/>;

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Contact - KayInLA";
  }, []);

  const submitFormHandler = (event) => {
    event.preventDefault();
    setIsLoading(true);

    const templateParams = {
      from_name: contactForm.name,
      from_email: contactForm.email,
      subject: contactForm.subject,
      message: contactForm.message,
    };

     emailjs.send(
      'gmail',
      'template_contact_kay',
      templateParams,
      process.env.REACT_APP_USER_ID
     ).then((response) => {
       setIsLoading(false);
       setShowModal(true);
       setModalTitle(<h2>Your message was sent! <span role="img" aria-label="eyes">🤗</span></h2>);
       setModalContent('Thank you for reaching out! Kay will get back to you soon.');
     }, (err) => {
       setIsLoading(false);
       setShowModal(true);
       setModalTitle(<h2>Something went wrong! <span role="img" aria-label="eyes">👀</span></h2>);
       setModalContent(err.message);
     });
  };

  const inputChangedHandler = (event, inputId) => {
    const updatedForm = { ...contactForm, [inputId]: event.target.value };
    setContactForm(updatedForm);
  };

  const closeModal = () => {
    setShowModal(false);
    window.location.reload();
  };

  const isDisabled = contactForm.name === '' || contactForm.email === '' || contactForm.message === '';

  return (
    <Fragment>
      {showModal && <Modal title={modalTitle} show={showModal} onClose={closeModal}>{modalContent}</Modal>}
      <Page>
        <h1>Get inTouch</h1>
        <br/>
        <RowContent>
          <div className="ImageTop">
            {didLoad ? null : <Loader/>}
            {image}
          </div>
          <form>
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={contactForm.name}
              onChange={(event) => inputChangedHandler(event, 'name')}
              required/>
            <br/><br/>
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={contactForm.email}
              onChange={(event) => inputChangedHandler(event, 'email')}
              required/>
            <br/><br/>
            <input
              type="text"
              name="subject"
              placeholder="Subject"
              value={contactForm.subject}
              onChange={(event) => inputChangedHandler(event, 'subject')}/>
            <br/><br/>
            <textarea
              rows="12"
              name="message"
              placeholder="Your Message"
              value={contactForm.message}
              onChange={(event) => inputChangedHandler(event, 'message')}
              required></textarea>
            <br/><br/>
            <Button disabled={isDisabled} btnType="Submit" clicked={submitFormHandler}>CONTACT ME</Button>
          </form>
        </RowContent>
        {isLoading && <Loader style={{width: '50px', height: '50px'}}/>}
        <TopButton />
      </Page>
    </Fragment>
  );
}

export default Contact;
