import React from 'react';

import classes from './SocialBarItem.module.css';

function SocialBarItem(props) {
  return (
    <li className={classes.SocialBarItem}>
      <a href={props.link} rel="noopener noreferrer" target="_blank">
        <img src={props.imgsrc} alt={props.imgalt}/>
      </a>
    </li>
  );
}

export default SocialBarItem;
