import React from 'react';
import { NavLink } from 'react-router-dom';

import classes from './NavigationItem.module.css';

function NavigationItem(props) {
  let links = null;
  if (props.external) {
    links = <li className={classes.NavigationItem} onClick={props.clicked}>
      <a href={props.link} target="_blank" rel="noopener noreferrer">{props.children}</a>
    </li>
  } else {
    links = <li className={classes.NavigationItem} onClick={props.clicked}>
      <NavLink
        to={props.link}
        exact={props.exact}
        activeClassName={classes.active}>
        {props.children}
      </NavLink>
    </li>
  }
  return (
    <div>
      {links}
    </div>
  );
}

export default NavigationItem;
