import React, { Fragment } from "react";

import Logo from "../../Logo/Logo";
import SocialBar from "../SocialBar/SocialBar";
import NavigationItems from "../NavigationItems/NavigationItems";
import Subscribe from "../../Subscribe/Subscribe";
import Backdrop from "../../UI/Backdrop/Backdrop";
import classes from "./SideDrawer.module.css";

function Sidedrawer(props) {
  let attachedClasses = [classes.SideDrawer, classes.Close];
  if (props.open) {
    attachedClasses = [classes.SideDrawer, classes.Open];
  }

  return (
    <Fragment>
      <Backdrop show={props.open} clicked={props.closed} />
      <div className={attachedClasses.join(" ")}>
        <div className={classes.CloseButton} onClick={props.closed}>
          <div className={classes.barOne}></div>
          <div className={classes.barTwo}></div>
        </div>
        <div className={classes.Logo}>
          <Logo />
        </div>
        <nav>
          <NavigationItems clicked={props.closed} />
        </nav>
        <div className={classes.SocialBar}>
          <SocialBar search />
        </div>
        <div className={classes.Subscribe}>
          <Subscribe />
        </div>
      </div>
    </Fragment>
  );
}

export default Sidedrawer;
