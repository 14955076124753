import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import classes from './BlogItems.module.css';
import BlogItem from './BlogItem/BlogItem';

function BlogItems(props) {
  const history = useHistory();
  const defaultState = {
    all: false,
    beauty: false,
    style: false,
    sustainability: false,
    dance: false
  };
  const [isActive, setIsActive] = useState({...defaultState, [props.category]: true});

  useEffect(() => {
    const defaultState = {
      all: false,
      beauty: false,
      style: false,
      sustainability: false,
      dance: false
    };
    setIsActive({...defaultState, [props.category]: true});
  }, [props.category]);

  const historyReplace = (route) => {
    history.replace(route);
  }

  return (
    <ul className={classes.BlogItems}>
      <BlogItem
        active={isActive['all']}
        clicked={() => historyReplace('/blog')}
        category="ALL">
      </BlogItem>
      <BlogItem
        active={isActive['beauty']}
        clicked={() => historyReplace('/blog/category/beauty')}
        category="BEAUTY">
      </BlogItem>
      <BlogItem
        active={isActive['style']}
        clicked={() => historyReplace('/blog/category/style')}
        category="STYLE">
      </BlogItem>
      <BlogItem
        active={isActive['sustainability']}
        clicked={() => historyReplace('/blog/category/sustainability')}
        category="SUSTAINABILITY">
      </BlogItem>
      <BlogItem
        active={isActive['dance']}
        clicked={() => historyReplace('/blog/category/dance')}
        category="DANCE">
      </BlogItem>
    </ul>
  );
}

export default BlogItems;
