import React, { Fragment, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import Comments from '../Comments/Comments';
import classes from './Post.module.css';

function Post(props) {
  const history = useHistory();
  const postElement = useRef();

  const title = props.title.toUpperCase();
  const category = props.category.replace(/^./, props.category[0].toUpperCase())
  const date = new Date(props.date);
  const month = date.toLocaleString('default', { month: 'long' });
  const day = date.getDate();
  const year = date.getFullYear();
  const datePublished = month + ' ' + day + ', ' + year;
  const featureImages = props.featureImages.split(',');
  const bodyImages = props.bodyImages.split(',');

  const showFullPostHandler = () => {
    history.push(`/blog/${props.link}`);
  };

  const showFullPostOnEnter = (event) => {
    if (event.keyCode === 13) {
      history.push(`/blog/${props.link}`);
    }
  };

  let images = [];
  if (featureImages) {
    let imgStyle = {};
    if (featureImages.length === 2) {
      imgStyle = {width: '50%'};
    } else {
      imgStyle = {width: '100%'};
    }
    images = featureImages.map((url, index) => <img key={index} src={url} alt={`${props.title}`} style={imgStyle}/>);
  }

  useEffect(() => {
    const contentImages = postElement.current.querySelectorAll("img");
    for (let i=featureImages.length; i < contentImages.length; i++) {
      contentImages[i].src = bodyImages[i - featureImages.length];
    }
  }, [bodyImages, featureImages]);

  return (
    <Fragment>
      <div
        id="post"
        ref={postElement}
        tabIndex="0"
        className={classes.Post}
        onClick={showFullPostHandler}
        onKeyDown={showFullPostOnEnter}>
        <h1>{title}</h1>
        <h3>{datePublished} - {category}</h3>
        <div className={classes.Feature}>
          {images}
        </div>
        <p dangerouslySetInnerHTML={ { __html: props.content } }></p>
      </div>
      {props.showComments && <Comments list={props.comments} postId={props.id}/>}
    </Fragment>
  );
}

export default React.memo(Post);
