import React, { Fragment, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Masonry from "react-masonry-component";

import Post from "../../components/Post/Post";
import PostPreview from "../../components/Post/PostPreview/PostPreview";
import BlogItems from "../../components/BlogItems/BlogItems";
import Modal from "../../components/UI/Modal/Modal";
import Loader from "../../components/UI/Loader/Loader";
import TopButton from "../../components/UI/TopButton/TopButton";
import SubscribeModal from "../../components/UI/Modal/SubscribeModal/SubscribeModal";
import * as actions from "../../store/actions/index";
import classes from "./Blog.module.css";

function Blog(props) {
  const postTitle = props.match.params.postTitle;
  const currentCategory = props.match.params.categoryName;

  const [showModal, setShowModal] = useState(false);
  const [modalViewed, setModalViewed] = useState(false);

  const posts = useSelector((state) => state.blogPosts.posts);
  const error = useSelector((state) => state.blogPosts.error);
  const loading = useSelector((state) => state.blogPosts.loading);

  const dispatch = useDispatch();

  const onFetchPosts = useCallback(() => dispatch(actions.fetchPosts()), [
    dispatch,
  ]);
  const onErrorConfirmed = () => dispatch(actions.errorConfirmed());

  useEffect(() => {
    onFetchPosts();
  }, [onFetchPosts]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Blog - KayInLA";
    const timer = setTimeout(() => {
      setShowModal(true);
    }, 60 * 1000);
    return () => clearTimeout(timer);
  }, [postTitle]);

  let renderPosts = error ? <p>Posts could not be loaded!</p> : <Loader />;
  let preview;

  if (!loading && !error) {
    if (postTitle !== undefined) {
      preview = false;
      const filteredPosts = posts.filter((post) => post.link === postTitle);
      renderPosts = filteredPosts.map((post) => (
        <Post
          key={post.id}
          id={post.id}
          title={post.title}
          date={post.date}
          category={post.category}
          featureImages={post.featureImages}
          bodyImages={post.bodyImages}
          content={post.content}
          comments={post.comments}
          link={post.link}
          showComments
        />
      ));
    } else if (currentCategory !== undefined) {
      preview = true;
      const filteredPosts = posts.filter(
        (post) => post.category === currentCategory
      );
      renderPosts = filteredPosts.map((post) => (
        <PostPreview
          key={post.id}
          title={post.title}
          date={post.date}
          category={post.category}
          featureImages={post.featureImages}
          content={post.content}
          link={post.link}
        />
      ));
    } else {
      preview = true;
      renderPosts = posts.map((post) => (
        <PostPreview
          key={post.id}
          title={post.title}
          date={post.date}
          category={post.category}
          featureImages={post.featureImages}
          content={post.content}
          link={post.link}
        />
      ));
    }
    renderPosts =
      posts.length === 0 ? (
        <h2>
          No posts yet!{" "}
          <span role="img" aria-label="tears">
            😢
          </span>
        </h2>
      ) : (
        renderPosts
      );
  }

  const modalTitle = (
    <h2>
      Something went wrong!{" "}
      <span role="img" aria-label="eyes">
        👀
      </span>
    </h2>
  );

  return (
    <Fragment>
      {!modalViewed && showModal && (
        <SubscribeModal setModal={setShowModal} setViewed={setModalViewed} />
      )}
      {error && (
        <Modal title={modalTitle} show onClose={onErrorConfirmed}>
          {error}
        </Modal>
      )}
      {preview ? (
        <BlogItems
          category={currentCategory === undefined ? "all" : currentCategory}
        />
      ) : null}
      {preview && posts ? (
        <Masonry className={classes.Content}>{renderPosts}</Masonry>
      ) : (
        <div className={classes.Content}>{renderPosts}</div>
      )}
      <TopButton />
    </Fragment>
  );
}

export default Blog;
