import React from 'react';

import classes from './NavigationItems.module.css';
import NavigationItem from './NavigationItem/NavigationItem';

function NavigationItems(props) {
  return (
    <ul className={classes.NavigationItems}>
      <NavigationItem link="/" clicked={props.clicked} exact>HOME</NavigationItem>
      <NavigationItem link="/about" clicked={props.clicked}>ABOUT</NavigationItem>
      <NavigationItem link="/blog" clicked={props.clicked}>BLOG</NavigationItem>
      <NavigationItem link="/contact" clicked={props.clicked}>CONTACT</NavigationItem>
      <NavigationItem link="https://www.bonfire.com/store/kayinla87" external>MERCH</NavigationItem>
    </ul>
  );
}

export default NavigationItems;
