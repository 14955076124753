import React, { useState } from 'react';
import { useHistory } from "react-router-dom";

import classes from './SearchBar.module.css';

function SearchBar(props) {
  const history = useHistory();
  const [enteredSearch, setEnteredSearch] = useState('');
  const { onSubmitSearch, show } = props;

  let activeClasses = [classes.SearchBar];

  if (show) {
    activeClasses.push(classes.Show);
  }

  const keyPressHandler = (event) => {
    if (event.key === 'Enter') {
      onSubmitSearch(false);
      const searchParam = enteredSearch.replace(/\s+/g, '-');
      history.push(`/search/${searchParam}`);
      setEnteredSearch('');
    }
  };

  return (
    <div className={activeClasses.join(' ')}>
      <input
        type="text"
        value={enteredSearch}
        placeholder="Search and hit enter..."
        onChange={event => setEnteredSearch(event.target.value)}
        onKeyPress={keyPressHandler} />
    </div>
  );

}

export default SearchBar;
