import React, { Fragment, useState } from 'react';

import SubComment from '../SubComment/SubComment';
import Reply from '../../Reply/Reply';
import classes from './Comment.module.css';

function Comment(props) {
  const [showReply, setShowReply] = useState(false);

  const author = props.author.toUpperCase();
  const date = new Date(props.date);
  const month = date.toLocaleString('default', { month: 'long' });
  const day = date.getDate();
  const year = date.getFullYear();
  const dateCreated = month + ' ' + day + ', ' + year;
  const timeCreated = date.toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'});
  const subComments = props.subComments;

  const showReplyHandler = () => {
    setShowReply(true);
    props.showCommentReply(false);
  };

  const subcomments = Object.keys(subComments).map(key => (
    <SubComment
      key={key}
      author={subComments[key].author}
      date={subComments[key].date}
      content={subComments[key].content} />
  ));

  const reply = showReply ?
  <Reply
    isReply={showReply}
    show={setShowReply}
    belongsTo={props.commentId}
    post={props.postId}
    showCommentReply={props.showCommentReply} /> : null;

  return (
    <Fragment>
      <div className={classes.Comment}>
        <div className={classes.Heading}>
          <div className={classes.Details}>
            <h4>{author}</h4>
            <p>{dateCreated} at {timeCreated}</p>
          </div>
          <button onClick={showReplyHandler}>REPLY →</button>
        </div>
        <div className={classes.Content}>
          <p>{props.content}</p>
        </div>
      </div>
      {subcomments}
      {reply}
    </Fragment>
  );
}

export default Comment;
