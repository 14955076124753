import React, { Fragment, useState } from "react";
import { database } from "../../services/firebase";
import emailjs from "emailjs-com";

import Button from "../UI/Button/Button";
import Modal from "../UI/Modal/Modal";
import classes from "./Reply.module.css";

function Reply(props) {
  const [replyForm, setReplyForm] = useState({
    name: "",
    email: "",
    comment: "",
    notify: false,
    subscribe: false,
  });
  const [error, setError] = useState(null);

  let activeClasses = [classes.Comment];
  let btnType = "Comment";

  if (props.isReply) {
    activeClasses.pop();
    activeClasses.push(classes.Reply);
    btnType = "Reply";
  }

  const inputChangedHandler = (event, inputId) => {
    const updatedForm = { ...replyForm, [inputId]: event.target.value };
    setReplyForm(updatedForm);
  };

  const cancelReplyHandler = () => {
    props.show(false);
    if (props.showCommentReply) {
      props.showCommentReply(true);
    }
  };

  const notifyOtherCommenters = () => {
    try {
      database
        .ref("posts")
        .child(`${props.post}/emails`)
        .once("value", (snapshot) => {
          snapshot.forEach((snap) => {
            const post = snap.val();
            const templateParams = {
              to_email: post.email,
              subject: "KayInLA Blog New Comment",
              message: `${replyForm.name} just added a comment to the ${post.title} blog post. Go check it out!`,
            };

            emailjs
              .send(
                "gmail",
                "template_subscribed",
                templateParams,
                process.env.REACT_APP_USER_ID
              )
              .then(
                (response) => {},
                (err) => {
                  setError(err.message);
                }
              );
          });
        });
    } catch (error) {
      setError(error.message);
    }
  };

  const submitReplyFormHandler = (event) => {
    event.preventDefault();

    const commentDate = new Date();
    const newComment = {
      author: replyForm.name,
      email: replyForm.email,
      date: commentDate.toString(),
      content: replyForm.comment,
      replies: "",
    };

    if (props.isReply) {
      try {
        database
          .ref("posts")
          .child(`${props.post}/comments/${props.belongsTo}/replies`)
          .push(newComment);
        notifyOtherCommenters();
      } catch (error) {
        setError(error.message);
      }
    } else {
      try {
        database.ref("posts").child(`${props.post}/comments`).push(newComment);
        notifyOtherCommenters();
      } catch (error) {
        setError(error.message);
      }
    }

    if (replyForm.subscribe) {
      try {
        database.ref("subscribed").push({ email: replyForm.email });
      } catch (error) {
        setError(error.message);
      }
    }

    if (replyForm.notify) {
      try {
        database
          .ref("posts")
          .child(`${props.post}/emails`)
          .push({ email: replyForm.email });
      } catch (error) {
        setError(error.message);
      }
    }

    window.location.reload();
  };

  const closeModal = () => {
    setError(null);
  };

  const isDisabled =
    replyForm.name === "" || replyForm.email === "" || replyForm.comment === "";
  const errorTitle = (
    <h2>
      Something went wrong!{" "}
      <span role="img" aria-label="eyes">
        👀
      </span>
    </h2>
  );

  return (
    <Fragment>
      {error && (
        <Modal title={errorTitle} show onClose={closeModal}>
          {error}
        </Modal>
      )}
      <div className={activeClasses}>
        <div className={classes.Header}>
          <h3>LEAVE A REPLY</h3>
          {props.isReply && (
            <button type="cancel" onClick={cancelReplyHandler}>
              CANCEL REPLY
            </button>
          )}
        </div>
        <textarea
          rows="7"
          name="message"
          placeholder="Enter your comment here..."
          value={replyForm.comment}
          onChange={(event) => inputChangedHandler(event, "comment")}
          required
        ></textarea>
        <input
          type="text"
          name="name"
          placeholder="Name (required)"
          value={replyForm.name}
          onChange={(event) => inputChangedHandler(event, "name")}
          required
        />
        <br />
        <input
          type="email"
          name="email"
          placeholder="Email (required)"
          value={replyForm.email}
          onChange={(event) => inputChangedHandler(event, "email")}
          required
        />
        <div className={classes.Submit}>
          <div className={classes.Options}>
            <input
              type="checkbox"
              name="newcomment"
              value={replyForm.notify}
              onChange={(event) => inputChangedHandler(event, "notify")}
            />
            <label> Notify me of new comments by email</label>
            <br />
            <input
              type="checkbox"
              name="newposts"
              value={replyForm.subscribe}
              onChange={(event) => inputChangedHandler(event, "subscribe")}
            />
            <label> Notify me of new posts by email</label>
            <br />
          </div>
          <br />
          <Button
            disabled={isDisabled}
            btnType={btnType}
            clicked={submitReplyFormHandler}
          >
            POST COMMENT
          </Button>
        </div>
      </div>
    </Fragment>
  );
}

export default Reply;
