import React, { Fragment, useState } from "react";
import { database } from "../../../../services/firebase";
import emailjs from "emailjs-com";

import subscribeToKayInLA from "../../../../assets/images/subscribe.png";
import Backdrop from "../../Backdrop/Backdrop";
import classes from "./SubscribeModal.module.css";
import Loader from "../../Loader/Loader";

function SubscribeModal(props) {
  const [isDisabled, setIsDisabled] = useState(true);
  const [didLoad, setDidLoad] = useState(false);
  const [email, setEmail] = useState("");

  const image = (
    <img
      style={didLoad ? {} : { visibility: "hidden" }}
      src={subscribeToKayInLA}
      alt="Kay in front of graffiti wall with red poles"
      onLoad={() => setDidLoad(true)}
    />
  );

  const closeModal = () => {
    props.setModal(false);
    if (props.setViewed) {
      props.setViewed(true);
    }
  };

  const addEmailHandler = () => {
    const newEmail = {
      email: email,
    };

    try {
      database.ref("subscribed").push(newEmail);
      props.setModal(false);
      if (props.setViewed) {
        props.setViewed(true);
      }
      const templateParams = {
        to_email: email,
        subject: "Thanks for subscribing!",
        message:
          "Thank you for subscribing! I look forward to sharing more authentic and hopefully inspiring content with you!" +
          " Feel free to reach out on Instagram, Snapchat, Youtube or Pinterest @kayinla87 if you have any questions." +
          " Stay tuned for more exciting announcements.",
      };

      emailjs
        .send(
          "gmail",
          "template_subscribed",
          templateParams,
          process.env.REACT_APP_USER_ID
        )
        .then(
          (response) => {},
          (err) => {
            console.log(err.message);
          }
        );
    } catch (error) {
      console.log(error.message);
    }
  };

  const checkValidity = (event) => {
    setEmail(event.target.value);
    const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    let isValid = pattern.test(event.target.value);
    isValid = event.target.value.trim() !== "" && isValid;
    setIsDisabled(!isValid);
  };

  return (
    <Fragment>
      <Backdrop show clicked={closeModal} />
      <div className={classes.SubscribeModal}>
        <div className={classes.Content}>
          <h2>Enjoying your time here?</h2>
          <p>Subscribe to KayInLA to stay up-to-date.</p>
          <div className={classes.Send}>
            <input
              type="email"
              placeholder="ENTER EMAIL"
              value={email}
              onChange={(event) => checkValidity(event)}
              required
            />
            <button
              type="submit"
              disabled={isDisabled}
              onClick={addEmailHandler}
            ></button>
          </div>
        </div>
        {didLoad ? null : <Loader />}
        {image}
        <div className={classes.CloseButton} onClick={closeModal}>
          <div className={classes.barOne}></div>
          <div className={classes.barTwo}></div>
        </div>
      </div>
    </Fragment>
  );
}

export default SubscribeModal;
