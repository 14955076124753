import React, { Fragment, useState, useEffect } from 'react';

import welcomeToKayInLA from '../../assets/images/welcomeToKayInLA.jpg';
import Image from '../../components/UI/Image/Image';
import Welcome from '../../components/Welcome/Welcome';
import Categories from '../../components/Welcome/Categories/Categories';
import TopButton from '../../components/UI/TopButton/TopButton';
import SubscribeModal from '../../components/UI/Modal/SubscribeModal/SubscribeModal';
import Loader from '../../components/UI/Loader/Loader';

function Home(props) {
  const [showModal, setShowModal] = useState(false);
  const [didLoad, setDidLoad] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "KayInLA";
    const timer = setTimeout(() => {
      setShowModal(true);
    }, 20*1000);
    return () => clearTimeout(timer);
  }, []);

  if (window.innerWidth < 850 && window.innerHeight > 1100) {
    let container = document.querySelectorAll("ul.Categories");
    let containerHeight;
    if (container[0] !== undefined) {
      containerHeight = container[0].clientHeight;
    }
    window.addEventListener('scroll', () => {
      let position = window.scrollY;
      let elems = document.querySelectorAll("li.Category");
      elems.forEach((elemItem) => {
        let target = elemItem.getBoundingClientRect().top + position;
        let id = elemItem.id;
        if (position >= target - 350) {
          elemItem.querySelector("a").style = 'background-color: rgba(220, 204, 192, 0.9)';
          elemItem.querySelector(`div[id=${id}Desc]`).style = 'opacity: 1';
        } else if (position >= containerHeight) {
          elemItem.querySelector("a").style = 'background-color: rgba(220, 204, 192, 0.9)';
          elemItem.querySelector(`div[id=${id}Desc]`).style = 'opacity: 1';
        } else {
          elemItem.querySelector("a").style = 'background-color: none';
          elemItem.querySelector(`div[id=${id}Desc]`).style = 'opacity: 0';
        }
      });
    });
  } else if (window.innerWidth < 850) {
    window.addEventListener('scroll', () => {
      let position = window.scrollY;
      let elems = document.querySelectorAll("li.Category");
      elems.forEach((elemItem) => {
        let target = elemItem.getBoundingClientRect().top + position;
        let id = elemItem.id;
        if (position >= target - 350) {
          elemItem.querySelector("a").style = 'background-color: rgba(220, 204, 192, 0.9)';
          elemItem.querySelector(`div[id=${id}Desc]`).style = 'opacity: 1';
        } else {
          elemItem.querySelector("a").style = 'background-color: none';
          elemItem.querySelector(`div[id=${id}Desc]`).style = 'opacity: 0';
        }
      });
    });
  }

  return (
    <Fragment>
      {showModal && <SubscribeModal setModal={setShowModal}/>}
      {didLoad ? null : <Loader/>}
      <Image
        style={didLoad ? {} : { visibility: 'hidden' }}
        src={welcomeToKayInLA}
        alt="Kay standing in doorway"
        onLoad={() => setDidLoad(true)}/>
      <Welcome />
      <Categories />
      {showModal ? null : <TopButton />}
    </Fragment>
  );
}

export default Home;
