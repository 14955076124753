import * as actionTypes from './actionTypes';

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START
  };
};

export const authSuccess = (token, userId) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    idToken: token,
    userId: userId
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error
  };
};

export const logout = () => {
  return {
    type: actionTypes.AUTH_INITIATE_LOGOUT
  }
};

export const didLogout = () => {
  return {
    type: actionTypes.AUTH_LOGOUT
  }
};

export const setAuthRedirect = (path) => {
  return {
    type: actionTypes.AUTH_REDIRECT,
    path: path
  }
};

export const auth = (email, password) => {
  return {
    type: actionTypes.AUTH_USER,
    email: email,
    password: password
  }
};

export const authCheckState = () => {
  return {
    type: actionTypes.AUTH_CHECK_STATE
  }
};

export const authErrorConfirmed = () => {
  return {
    type: actionTypes.AUTH_ERROR_CONFIRMED
  }
};
