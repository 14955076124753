import { takeEvery, all } from 'redux-saga/effects';

import * as actionTypes from '../actions/actionTypes'
import {
  fetchPostsSaga,
  fetchSearchedPostsSaga
 } from './blogPosts';

 import {
  logoutSaga,
  authUserSaga,
  authCheckStateSaga } from './auth';


export function* watchBlogPosts() {
  yield takeEvery(actionTypes.FETCH_POSTS, fetchPostsSaga);
  yield takeEvery(actionTypes.FETCH_SEARCHED_POSTS, fetchSearchedPostsSaga);
}

export function* watchAuth() {
  yield all([
    takeEvery(actionTypes.AUTH_INITIATE_LOGOUT, logoutSaga),
    takeEvery(actionTypes.AUTH_USER, authUserSaga),
    takeEvery(actionTypes.AUTH_CHECK_STATE, authCheckStateSaga)
  ]);
}
