import React from 'react';

import './RowContent.css';

function RowContent(props) {
  return (
    <div className="Content">
      {props.children}
    </div>
  );
}

export default RowContent;
