import { eventChannel, END } from 'redux-saga';
import { put, call, take } from 'redux-saga/effects';
import { auth } from '../../services/firebase';

import * as actions from '../actions/index';

export function* logoutSaga(action) {
  try {
    auth.signOut();
    yield put(actions.didLogout());
  } catch (error) {
    yield put(actions.authFail(error.message));
  }
}

export function* authUserSaga(action) {
  yield put(actions.authStart());
  try {
    const result = yield auth.signInWithEmailAndPassword(action.email, action.password);
    const token = yield result.user._lat;
    const userId = yield result.user.uid;
    yield put(actions.authSuccess(token, userId));
  } catch (error) {
    yield put(actions.authFail(error.message));
  }
}

function getAuthChannel() {
  return eventChannel(emitter => {
    const authState = auth.onAuthStateChanged(user => {
      if (user) {
        emitter(user)
      } else {
        emitter(END);
      }
    });
    return authState;
  });
}

export function* authCheckStateSaga(action) {
  const channel = yield call(getAuthChannel);
  try {
    while (true) {
      let user = yield take(channel);
      yield put(actions.authSuccess(user._lat, user.uid));
    }
  } finally {
    // console.log('No user signed in!');
  }
}
