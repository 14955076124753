import React from 'react';

import classes from './TopButton.module.css';

function TopButton(props) {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const scrollToTopOnEnter = (event) => {
    if (event.keyCode === 13) {
      window.scrollTo(0, 0);
    }
  };

  return (
    <div className={classes.TopButton}>
      <button onClick={scrollToTop} onKeyDown={scrollToTopOnEnter} aria-label="Scroll to top">
        <div className={classes.BarLeft}></div>
        <div className={classes.BarRight}></div>
      </button>
    </div>
  );
}

export default TopButton;
