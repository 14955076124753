import React from 'react';
import { useHistory, Link } from 'react-router-dom';

import './Category.css';

function Category(props) {
  const history = useHistory();
  const keyPressHandler = (event) => {
    if (event.keyCode === 13) {
      history.push(`${props.link}`);
    }
  };

  return (
    <li id={props.id} className="Category" tabIndex="0" onKeyDown={keyPressHandler}>
      <Link
        to={props.link}
        exact={props.exact}>
        <img src={props.imgsrc} alt={props.imgalt}/>
        <div id={`${props.id}Desc`} className="Description">
          <p>{props.children}</p>
        </div>
      </Link>
      <div className="Title" tabIndex="0" onKeyDown={keyPressHandler}>
        <Link
          to={props.link}
          exact={props.exact}>
          <h2>{props.title}</h2>
        </Link>
      </div>
    </li>
  );
}

export default Category;
