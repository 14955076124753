import React, { useState, useEffect, Fragment } from 'react';

import aboutKayInLA from '../../assets/images/aboutKayInLA.jpg';
import Page from '../../components/Page/Page';
import RowContent from '../../components/Page/RowContent/RowContent';
import TopButton from '../../components/UI/TopButton/TopButton';
import Loader from '../../components/UI/Loader/Loader';

function About(props) {
  const [didLoad, setDidLoad] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "About - KayInLA";
  }, []);

  const image = <img style={didLoad ? {} : { visibility: 'hidden' }}
                     src={aboutKayInLA}
                     alt="KayInLA standing in front of a building"
                     onLoad={() => setDidLoad(true)} />;

  return (
    <Fragment>
      <Page>
        <h1>About Kay</h1>
        <br/>
        <RowContent>
          <div className="ImageLeft">
            {didLoad ? null : <Loader/>}
            {image}
          </div>
          <br/>
          <div className="Text">
            <h3>WHO IS KAY COLEMAN?</h3>
            <br/>
            <p><strong>Three words to describe myself:</strong></p>
            <ul>
              <li>
                <strong>Vibrant: </strong>
                They say laughter is the best medicine. I couldn't agree more! I
                love being silly and having a good time with whoever is willing to
                partake in a good laugh.
              </li>
              <br/>
              <li>
                <strong>Unique: </strong>
                I love being an individual. I've never been one to
                follow the crowd, and I'm not afraid to stand up for what I believe in.
              </li>
              <br/>
              <li>
                <strong>Wise: </strong>
                I've been told since I was young that I have wisdom beyond my years.
                Recently, I’ve come to realize just how true this is.
              </li>
            </ul>
            <p><strong>In my spare time I like to:</strong></p>
              <ul>
                <li>
                  <strong>Dance: </strong>
                  Uhhh… DUH! Checkout where I'll be teaching next on my <a
                  href="https://www.instagram.com/kayinla87/"
                  rel="noopener noreferrer"
                  target="_blank">Instagram</a>.
                </li>
                <br/>
                <li>
                  <strong>Sing: </strong>
                  I’m a novice when it comes to singing, but I can hold a tune and
                  get a little fancy with it now <span role="img" aria-label="silly">😜</span>
                </li>
                <br/>
                <li>
                  <strong>Make new Pinterest boards: </strong>
                  Let’s just say I take my Pinterest boards very seriously… <a
                  href="https://www.pinterest.com/kayinla87/"
                  rel="noopener noreferrer" target="_blank">Explore my boards</a>.
                </li>
                <br/>
                <li>
                  <strong>Watch basketball: </strong>
                  I live for the NBA finals. I don’t have a favorite team, but
                  I have several players I enjoy watching.
                </li>
              </ul>
            <p>
              <strong>One thing I hate to admit about myself:</strong>
            </p>
            <p>
              I'm a hopeless romantic. Ok I said it. <span role="img" aria-label="laughing">😂 </span>
            But really… I'm a sucker for love y'all. Find someone who loves the most authentic
            version of yourself and never let them go. <span role="img" aria-label="heart eyes">😍</span>
            </p>
          </div>
        </RowContent>
      </Page>
      <TopButton />
    </Fragment>
  );
}

export default About;
