import React from 'react';

import brownPaperBackground from '../../assets/images/brownPaperBackground.png';
import classes from './Welcome.module.css';

function Welcome(props) {
  return (
    <div className={classes.Welcome}>
      <img src={brownPaperBackground} alt="Crumpled brown paper" />
      <div className={classes.Transbox}>
        <h1>Welcome</h1>
        <p>
          Welcome to the land of Kay where I discuss <em>everything</em> I'm passionate about.
          Whether it's sustainability, clean beauty, style, or dance, I'm here to talk about it.
          We stan for Black owned businesses/brands here, so feel free to message ya girl with
          suggestions. Well, what are you waiting for? Go ahead and explore!
        </p>
      </div>
    </div>
  );
}

export default Welcome;
