import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  posts: [],
  error: null,
  loading: false,
};

const addPost = (state, action) => {
  const updatedPosts = state.posts.concat(action.posts);
  const updatedState = {
    posts: updatedPosts,
    loading: false
  }
    return updateObject(state, updatedState);
};

const removePost = (state, action) => {
  const updatedPosts = state.posts.splice(action.postId, 1);
  const updatedState = {
    posts: updatedPosts,
    loading: false
  }
    return updateObject(state, updatedState);
};

const setPosts = (state, action) => {
  return updateObject(state, {
    posts: action.posts,
    loading: false
  });
};

const setError = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_POST: return addPost(state, action);
    case actionTypes.REMOVE_POST: return removePost(state, action);
    case actionTypes.FETCH_POSTS_START: return updateObject(state, { loading: true });
    case actionTypes.FETCH_POSTS_SUCCESS: return setPosts(state, action);
    case actionTypes.FETCH_POSTS_FAILED: return setError(state, action);
    case actionTypes.ERROR_CONFIRMED: return updateObject(state, initialState);
    default: return state;
  }
};

export default reducer;
