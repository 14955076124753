import React, { useState } from "react";

import Comment from "./Comment/Comment";
import Reply from "../Reply/Reply";
import classes from "./Comments.module.css";

function Comments(props) {
  const [showReply, setShowReply] = useState(true);

  const renderComments = Object.keys(props.list).map((key) => {
    return (
      <Comment
        key={key}
        commentId={key}
        postId={props.postId}
        author={props.list[key].author}
        date={props.list[key].date}
        content={props.list[key].content}
        subComments={props.list[key].replies}
        showCommentReply={setShowReply}
      />
    );
  });

  const heading =
    renderComments === [] ? <h2>NO COMMENTS</h2> : <h2>COMMENTS</h2>;

  return (
    <div className={classes.Comments}>
      {heading}
      {renderComments}
      {showReply && (
        <Reply isReply={false} show={setShowReply} post={props.postId} />
      )}
    </div>
  );
}

export default Comments;
