import React from 'react';
import { useHistory } from 'react-router-dom';

import classes from './PostPreview.module.css';

function PostPreview(props) {
  const thumbnail = props.featureImages.split(',')[0];
  const title = props.title.toUpperCase();
  const date = new Date(props.date);
  const month = date.toLocaleString('default', { month: 'long' });
  const day = date.getDate();
  const year = date.getFullYear();
  const datePublished = month + ' ' + day + ', ' + year;
  const history = useHistory();

  const showFullPostHandler = () => {
    history.push(`/blog/${props.link}`);
  };

  const showFullPostOnEnter = (event) => {
    if (event.keyCode === 13) {
      history.push(`/blog/${props.link}`);
    }
  };

  const maxLength = 400;
  let previewText = '';
  if (props.content.length > maxLength) {
    const truncatedText = props.content.substring(0, maxLength);
    previewText = truncatedText.substring(0, truncatedText.lastIndexOf(" ")) + "&hellip;";
  }

  return (
    <div className={classes.Preview} tabIndex="0" onClick={showFullPostHandler} onKeyDown={showFullPostOnEnter}>
      <img src={thumbnail} alt={`${props.title}`}/>
      <h1>{title}</h1>
      <p dangerouslySetInnerHTML={ { __html: previewText } }></p>
      <h3>{datePublished}</h3>
    </div>
  );
}

export default PostPreview;
