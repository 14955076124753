import React, { Suspense, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";

import Layout from "./hoc/Layout/Layout";
import Home from "./containers/Home/Home";
import About from "./containers/About/About";
import Blog from "./containers/Blog/Blog";
import Contact from "./containers/Contact/Contact";
import Footer from "./components/Footer/Footer";
import Loader from "./components/UI/Loader/Loader";
import Logout from "./containers/Admin/Logout/Logout";
import * as actions from "./store/actions/index";
import "./App.css";

const Search = React.lazy(() => {
  return import("./containers/Search/Search");
});

const Merch = React.lazy(() => {
  return import("./containers/Merch/Merch");
});

const Unsubscribe = React.lazy(() => {
  return import("./containers/Unsubscribe/Unsubscribe");
});

const Admin = React.lazy(() => {
  return import("./containers/Admin/Admin");
});

const Console = React.lazy(() => {
  return import("./containers/Console/Console");
});

function App(props) {
  const isAuthenticated = useSelector((state) => state.auth.token !== null);
  const dispatch = useDispatch();
  const onCheckAuthState = useCallback(
    () => dispatch(actions.authCheckState()),
    [dispatch]
  );

  useEffect(() => {
    onCheckAuthState();
  }, [onCheckAuthState]);

  let routes = (
    <Switch>
      <Route
        path="/admin/console"
        exact
        render={(props) =>
          isAuthenticated ? <Console {...props} /> : <Redirect to="/admin" />
        }
      />
      <Route path="/admin" render={(props) => <Admin {...props} />} />
      <Route path="/about" component={About} />
      <Route path="/blog/category/:categoryName" exact component={Blog} />
      <Route path="/blog/:postTitle" exact component={Blog} />
      <Route path="/blog" component={Blog} />
      <Route path="/contact" component={Contact} />
      <Route
        path="/search/:searchParam"
        exact
        render={(props) => <Search {...props} />}
      />
      <Route
        path="/unsubscribe/:email"
        exact
        render={(props) => <Unsubscribe {...props} />}
      />
      <Route path="/logout" component={Logout} />
      <Route path="/" exact component={Home} />
      <Route exact path="/merch" render={(props) => <Merch {...props} />} />
      <Redirect from="blog/category" to="/blog" />
      <Redirect to="/" />
    </Switch>
  );

  return (
    <div className="App">
      <Layout>
        <Suspense fallback={<Loader />}>{routes}</Suspense>
      </Layout>
      <Footer />
    </div>
  );
}

export default withRouter(App);
