import React from 'react';

import classes from './Image.module.css';

function Image(props) {

  return (
    <div className={classes.Image}>
      <img style={props.style} src={props.src} alt={props.alt} onLoad={props.onLoad}/>
    </div>
  );
}

export default Image;
