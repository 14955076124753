import React from 'react';

import classes from './BlogItem.module.css';

function BlogItem(props) {
  let btnClass = "";
  if (props.active) {
    btnClass = classes.active;
  }

  return (
    <li className={classes.BlogItem}>
      <button className={btnClass} onClick={props.clicked}>{props.category}</button>
    </li>
  );
}

export default BlogItem;
