import React from 'react';

import classes from './Page.module.css';

function Page(props) {
  return (
    <div className={classes.Page}>
      {props.children}
    </div>
  );
}

export default Page;
