import React, { useState } from "react";
import { database } from "../../services/firebase";
import emailjs from "emailjs-com";

import classes from "./Subscribe.module.css";

function Subscribe(props) {
  const [isDisabled, setIsDisabled] = useState(true);
  const [email, setEmail] = useState("");

  const checkValidity = (event) => {
    setEmail(event.target.value);
    const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    let isValid = pattern.test(event.target.value);
    isValid = event.target.value.trim() !== "" && isValid;
    setIsDisabled(!isValid);
  };

  const addEmailHandler = () => {
    const newEmail = {
      email: email,
    };

    try {
      database.ref("subscribed").push(newEmail);
      const templateParams = {
        to_email: email,
        subject: "Thanks for subscribing!",
        message:
          "Thank you for subscribing! I look forward to sharing more authentic and hopefully inspiring content with you!" +
          " Feel free to reach out on Instagram, Snapchat, Youtube or Pinterest @kayinla87 if you have any questions." +
          " Stay tuned for more exciting announcements.",
      };

      emailjs
        .send(
          "gmail",
          "template_subscribed",
          templateParams,
          process.env.REACT_APP_USER_ID
        )
        .then(
          (response) => {
            setEmail("");
            setIsDisabled(true);
          },
          (err) => {
            console.log(err.message);
          }
        );
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div className={classes.Subscribe}>
      <p>Subscribe to the KayInLA newsletter to stay up-to-date.</p>
      <div className={classes.Send}>
        <input
          type="email"
          placeholder="ENTER EMAIL"
          value={email}
          onChange={(event) => checkValidity(event)}
          required
        />
        <button
          type="submit"
          disabled={isDisabled}
          onClick={addEmailHandler}
        ></button>
      </div>
    </div>
  );
}

export default Subscribe;
