import * as actionTypes from './actionTypes';

export const addPost = (posts) => {
  return {
    type: actionTypes.ADD_POST,
    posts: posts
  };
};

export const removePost = (id) => {
  return {
    type: actionTypes.REMOVE_POST,
    id: id
  };
};

export const fetchPosts = () => {
  return {
      type: actionTypes.FETCH_POSTS
  };
};

export const fetchPostsStart = () => {
  return {
    type: actionTypes.FETCH_POSTS_START
  };
};

export const fetchSearchedPosts = (param) => {
  return {
    type: actionTypes.FETCH_SEARCHED_POSTS,
    searchParam: param
  };
};

export const fetchPostsSuccess = (posts) => {
  return {
    type: actionTypes.FETCH_POSTS_SUCCESS,
    posts: posts
  };
};

export const fetchPostsFailed = (error) => {
  return {
    type: actionTypes.FETCH_POSTS_FAILED,
    error: error
  };
};

export const errorConfirmed = () => {
  return {
    type: actionTypes.ERROR_CONFIRMED
  };
};
