import React from "react";

import Subscribe from "../Subscribe/Subscribe";
import SocialBar from "../Navigation/SocialBar/SocialBar";
import classes from "./Footer.module.css";

function Footer(props) {
  return (
    <footer className={classes.Footer}>
      <div className={classes.Subscribe}>
        <Subscribe />
      </div>
      <div className={classes.Signature}>
        <h2>Kay Coleman</h2>
        <br />
        <p>Beauty & Sustainable Lifestyle Blogger</p>
      </div>
      <div className={classes.SocialBar}>
        <h3>@kayinla87</h3>
        <SocialBar />
      </div>
    </footer>
  );
}

export default Footer;
