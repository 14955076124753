export const ADD_POST = 'ADD_POST';
export const REMOVE_POST = 'REMOVE_POST';
export const FETCH_POSTS = 'FETCH_POSTS';
export const FETCH_SEARCHED_POSTS = 'FETCH_SEARCHED_POSTS';
export const FETCH_POSTS_START = 'FETCH_POSTS_START';
export const FETCH_POSTS_SUCCESS = 'FETCH_POSTS_SUCCESS';
export const FETCH_POSTS_FAILED = 'FETCH_POSTS_FAILED';
export const ERROR_CONFIRMED = 'ERROR_CONFIRMED';

export const AUTH_CHECK_STATE = 'AUTH_CHECK_STATE';
export const AUTH_USER = 'AUTH_USER';
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_INITIATE_LOGOUT = 'AUTH_INITIATE_LOGOUT';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_REDIRECT = 'AUTH_REDIRECT';
export const AUTH_ERROR_CONFIRMED = 'AUTH_ERROR_CONFIRMED';
