import React from 'react';

import classes from './Loader.module.css';

function Loader(props) {
  return (
    <div className={classes.Container}>
      <div className={classes.Loader}>
        <div className={classes.LoaderDot}></div>
        <div className={classes.LoaderDot}></div>
        <div className={classes.LoaderDot}></div>
        <div className={classes.LoaderDot}></div>
        <div className={classes.LoaderDot}></div>
        <div className={classes.LoaderDot}></div>
      </div>
    </div>
  );
}

export default Loader;
