import React from 'react';

import visitorAvatar from '../../../assets/images/avatar.png';
import kayAvatar from '../../../assets/images/kayAvatar.png';
import classes from './SubComment.module.css';

function SubComment(props) {

  const author = props.author.toUpperCase();
  const date = new Date(props.date);
  const month = date.toLocaleString('default', { month: 'long' });
  const day = date.getDate();
  const year = date.getFullYear();
  const dateCreated = month + ' ' + day + ', ' + year;
  const timeCreated = date.toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'});

  const avatar = author === 'KAY' ? kayAvatar : visitorAvatar;

  return (
    <div className={classes.SubComment}>
        <div className={classes.Heading}>
          <div className={classes.Details}>
            <h4>{author}</h4>
            <p>{dateCreated} at {timeCreated}</p>
          </div>
        </div>
        <div className={classes.Comment}>
          <div>
            <img src={avatar} alt="comment" />
          </div>
          <div className={classes.Content}>
            <p>{props.content}</p>
          </div>
      </div>
    </div>
  );
}

export default SubComment;
