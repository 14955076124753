import React from 'react';
import { Link } from 'react-router-dom';

import kayInLaLogo from '../../assets/images/KayInLALogoTransparent.png';
import classes from './Logo.module.css';

function Logo() {
  return (
    <Link to='/home' className={classes.Logo}>
      <img src={kayInLaLogo} alt="KayInLA"/>
    </Link>
  )
}

export default Logo;
