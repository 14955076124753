import React, { Fragment } from 'react';

import classes from './Modal.module.css';
import Backdrop from '../Backdrop/Backdrop';

function Modal(props) {
  return (
    <Fragment>
      <Backdrop show={props.show} clicked={props.onClose}/>
      <div className={classes.Modal}>
        {props.title}
        <p>{props.children}</p>
        <div className={classes.Action}>
          <button type="button" onClick={props.onClose}>
            Okay
          </button>
        </div>
      </div>
    </Fragment>
  );
}

export default Modal;
