import React, { useState } from "react";

import youtubeIcon from "../../../assets/images/youtubeIcon.png";
import instagramIcon from "../../../assets/images/instagramIcon.png";
import pinterestIcon from "../../../assets/images/pinterestIcon.png";
import searchIcon from "../../../assets/images/searchIcon.png";
import classes from "./SocialBar.module.css";
import SocialBarItem from "./SocialBarItem/SocialBarItem";
import SearchBar from "../SearchBar/SearchBar";

function SocialBar(props) {
  const [showSearchBar, setShowSearchBar] = useState(false);

  const toggleSearchBar = () => {
    setShowSearchBar(!showSearchBar);
  };

  const searchBar = props.search ? (
    <div>
      <button onClick={toggleSearchBar}>
        <img src={searchIcon} alt="Search" />
      </button>
      <SearchBar onSubmitSearch={setShowSearchBar} show={showSearchBar} />
    </div>
  ) : null;

  return (
    <ul className={classes.SocialBar}>
      <SocialBarItem
        link="https://youtube.com/channel/UCBoKXmw2q-wcu71wQiM8OPw"
        imgsrc={youtubeIcon}
        imgalt="KayInLA87 Youtube Channel"
      />
      <SocialBarItem
        link="https://www.instagram.com/kayinla87/"
        imgsrc={instagramIcon}
        imgalt="KayInLA Instagram Profile"
      />
      <SocialBarItem
        link="https://www.pinterest.com/kayinla87/"
        imgsrc={pinterestIcon}
        imgalt="KayInLA Pinterest Profile"
      />
      {searchBar}
    </ul>
  );
}

export default SocialBar;
