import { put } from 'redux-saga/effects';
import { database } from '../../services/firebase';

import * as actions from '../actions/index';

export function* fetchPostsSaga(action) {
  yield put(actions.fetchPostsStart());
  try {
    const loadedPosts = [];
    yield database.ref("posts").orderByChild("date").once("value", snapshot => {
      snapshot.forEach((snap) => {
        const post = snap.val();
        loadedPosts.push({
          id: snap.key,
          title: post.title,
          date: post.date,
          category: post.category,
          featureImages: post.featureImages,
          bodyImages: post.bodyImages,
          content: post.content,
          comments: post.comments,
          link: post.link
        });
      });
    });
    yield put(actions.fetchPostsSuccess(loadedPosts.reverse()));
  } catch (error) {
    yield put(actions.fetchPostsFailed(error.message));
  }
}

export function* fetchSearchedPostsSaga(action) {
  yield put(actions.fetchPostsStart());
  try {
    const loadedPosts = [];
    const postKeys = {};
    yield database.ref("posts").orderByChild("category").equalTo(`${action.searchParam}`)
    .once("value", snapshot => {
      snapshot.forEach((snap) => {
        const post = snap.val();
        postKeys[snap.key] = 1;
        loadedPosts.push({
          id: snap.key,
          title: post.title,
          date: post.date,
          category: post.category,
          featureImages: post.featureImages,
          bodyImages: post.bodyImages,
          content: post.content,
          comments: post.comments,
          link: post.link
        });
      });
    });

    yield database.ref("posts").orderByChild("title").once("value", snapshot => {
      snapshot.forEach((snap) => {
        const post = snap.val();
        if (post.title.toLowerCase().includes(action.searchParam)) {
          if (!(snap.key in postKeys)) {
            loadedPosts.push({
              id: snap.key,
              title: post.title,
              date: post.date,
              category: post.category,
              featureImages: post.featureImages,
              bodyImages: post.bodyImages,
              content: post.content,
              comments: post.comments,
              link: post.link
            });
          }
        }
      });
    });
    yield put(actions.fetchPostsSuccess(loadedPosts));
  } catch (error) {
    yield put(actions.fetchPostsFailed(error.message));
  }
}
