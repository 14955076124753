import React from 'react';

import beautyIcon from '../../../assets/images/beautyIcon.png';
import styleIcon from '../../../assets/images/styleIcon.png';
import sustainabilityIcon from '../../../assets/images/sustainabilityIcon.png';
import danceIcon from '../../../assets/images/danceIcon.png';
import './Categories.css';
import Category from './Category/Category';

function Categories(props) {
  return (
    <ul className="Categories">
      <Category
        id="beauty"
        title="Beauty"
        link={'blog/category/beauty'}
        imgsrc={beautyIcon}
        imgalt="KayInLA Beauty Blog Page" >
        Clean beauty is a must for me. I’m very conscious about the ingredients
        I put in and on my body. A lot of products that are marketed as "natural"
        aren’t really 100% natural and often contain harmful ingredients. Discover
        some of my favorite clean beauty products and DIY recipes on the
        Beauty page of my blog.
      </Category>
      <Category
        id="style"
        title="Style"
        link={'blog/category/style'}
        imgsrc={styleIcon}
        imgalt="KayInLA Style Blog Page">
        I would characterize my style as classic, minimal chic with a splash of
        vintage. I love mixing minimal designs with a bold statement piece or
        something that has a bit of character. Whether it’s clothing or interior
        design, I enjoy putting pieces together to create my own unique style.
        Browse the Style page of my blog.
      </Category>
      <Category
        id="sustainability"
        title="Sustainability"
        link={'blog/category/sustainability'}
        imgsrc={sustainabilityIcon}
        imgalt="KayInLA Sustainability Blog Page">
        Living a sustainable life is very important to me. My family members call
        me the recycling queen… well… because I am.  I’m always looking for ways
        I can reduce my waste, and recycling is just one small part of that.
        Explore the Sustainability page of my blog where I share my personal
        tips and tricks to living a more eco-conscious life.
      </Category>
      <Category
        id="dance"
        title="Dance"
        link={'blog/category/dance'}
        imgsrc={danceIcon}
        imgalt="KayInLA Dance Blog Page">
        I’m semi-professional dancer with a dream to dance on tour for a major
        recording artist. I consider myself a lifelong student committed to
        perfecting my craft while on the path to making my dreams come true.
        Read about some of my dance experiences and find out where I'll be
        teaching next on the Dance page of my blog.
      </Category>
    </ul>
  );
}

export default Categories;
